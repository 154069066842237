<template>
  <div class="content">
    <div class="ipt">
      <input v-model="telephone" type="number" placeholder="请输入活动【报名】时，登录用户的手机号">
      <van-button type="danger" @click="signIn">立即签到</van-button>
    </div>
    <van-popup v-model="signErr">
      <div class="signerr">
        <img class="signerr_img" src="@/static/signin/fail.png" alt="">
        <h2>签到失败</h2>
        <p>{{ errText }}</p>
        <van-button class="know" type="danger" @click="know">我知道了</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { checkMobileApi } from '@/api/signin'
import { Toast } from 'vant'
export default {
  data() {
    return {
      telephone: '',
      signErr: false,
      errText: '',
      act_id: 0
    }
  },
  created() {
    this.act_id = this.$route.query.act_id
  },
  methods: {
    signIn() {
      if (this.phone === '') {
        Toast('请输入有效手机号')
        return
      }
      if (!/^1[34578]\d{9}$/.test(this.telephone)) {
        Toast('手机号格式不正确')
        return
      }
      const params = {
        mobile: this.telephone,
        act_id: this.act_id
      }
      checkMobileApi(params).then(res => {
        if (res.errNo === 0) {
          Toast.success('签到成功')
          this.$router.push({ path: 'signinsuccess', query: { actId: this.act_id }})
        } else {
          this.signErr = true
          this.errText = res.msg
        }
      })
    },
    know() {
      this.signErr = false
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
    padding: 30px;
    .ipt {
        width: 100%;
        input {
            // padding-left: 10px;
            font-size: 30px;
            border: 1px solid #ccc;
            width: 100%;
            height: 100px;
            &::-webkit-input-placeholder {
                padding-left: 20px;
            }
        }
    }
}
.signerr {
    width: 600px;
    text-align: center;
    .signerr_img {
        width: 100px;
        height: 100px;
        margin: 30px 0 0 0;
    }
    h2 {
        margin: 20px;
    }
    p {
        color: #666666;
    }
    .know {
        width: 500px;
        margin-bottom: 20px;
        border-radius: 10px;
    }
}
/deep/ .van-button {
    width: 100%;
    margin-top: 30px;
}
/deep/ .van-popup {
    background: #fff;
    border-radius: 15px;
}
</style>
